<template>
  <div>
    <a-modal
      :visible="visible"
      title="计划排产"
      :width="1400"
      :confirmLoading="confirmLoading"
      :maskClosable="false"
      okText="排产"
      @cancel="cancel"
      @ok="confirm"
    >
      <a-row style="max-height: 400px; overflow: auto">
        <a-col :span="24">
          <a-table
            rowKey="id"
            size="small"
            :columns="columns"
            :data-source="productionTaskItems"
            :pagination="false"
            :scroll="{ x: 2200 }"
            :loading="queryLoading"
          >
            <template slot="workstation" slot-scope="value, item">
              <a-select v-model="item.workstation" :allowClear="true" size="small" style="width: 120px">
                <a-select-option
                  v-for="workstationItem in workstationItems"
                  :key="workstationItem.id"
                  :value="workstationItem.id"
                >
                  {{ workstationItem.name }}
                </a-select-option>
              </a-select>
            </template>

            <template slot="operator_set" slot-scope="value, item">
              <a-select v-model="item.operator_set" mode="multiple" size="small" :allowClear="true" style="width: 120px">
                <a-select-option v-for="item in userItems" :key="item.id" :value="item.id" :item="item">
                  {{ item.name }}
                </a-select-option>
              </a-select>
            </template>
            <template slot="total_quantity" slot-scope="value, item">
              <a-input-number v-model="item.total_quantity" size="small" />
            </template>

            <template slot="start_time" slot-scope="value, item">
              <a-date-picker
                v-model="item.start_time"
                placeholder="请选择时间"
                show-time
                size="small"
                valueFormat="YYYY-MM-DD HH:mm:ss"
              />
            </template>
            <template slot="complete_time" slot-scope="value, item">
              <a-date-picker
                v-model="item.complete_time"
                placeholder="请选择时间"
                show-time
                size="small"
                valueFormat="YYYY-MM-DD HH:mm:ss"
              />
            </template>
          </a-table>
        </a-col>
      </a-row>
    </a-modal>
  </div>
</template>

<script>
import { productionOrderProductionTask, productionOrderScheduling } from "@/api/production";
import { workstationOption, userOption } from "@/api/option";

export default {
  props: ["visible", "items"],
  model: { prop: "visible", event: "cancel" },
  data() {
    return {
      queryLoading: false,
      confirmLoading: false,
      productionTaskItems: [],
      workstationItems: [],
      userItems: [],

      columns: [
        {
          title: "序号",
          dataIndex: "index",
          width: 60,
          customRender: (value, item, index) => index + 1,
        },
        {
          title: "生产工单号",
          dataIndex: "production_order_number",
          width: 200,
        },
        {
          title: "物料编号",
          dataIndex: "goods_number",
        },
        {
          title: "物料名称",
          dataIndex: "goods_name",
        },
        {
          title: "物料规格",
          dataIndex: "goods_spec",
        },
        {
          title: "任务名称",
          dataIndex: "production_process",
          customRender: (value, item) => item.process_item.name,
        },
        {
          title: "工作站",
          dataIndex: "workstation",
          scopedSlots: { customRender: "workstation" },
        },
        {
          title: "操作员",
          dataIndex: "operator_set",
          scopedSlots: { customRender: "operator_set" },
        },
        {
          title: "排产数量",
          dataIndex: "total_quantity",
          scopedSlots: { customRender: "total_quantity" },
        },
        {
          title: "已生产数量",
          dataIndex: "production_quantity",
        },
        {
          title: "开始生产时间",
          dataIndex: "start_time",
          width: 240,
          scopedSlots: { customRender: "start_time" },
        },
        {
          title: "预计完成时间",
          dataIndex: "complete_time",
          width: 240,
          scopedSlots: { customRender: "complete_time" },
        },
        {
          title: "状态",
          dataIndex: "is_confirm",
          width: 80,
          customRender: (value) => (value ? "已确认" : "待确认"),
        },
      ],
    };
  },
  computed: {},
  methods: {
    async confirm() {
      const groupedData = this.productionTaskItems.reduce((acc, item) => {
        if (!acc[item.production_order]) {
          acc[item.production_order] = [];
        }
        acc[item.production_order].push(item);
        return acc;
      }, {});

      this.confirmLoading = true;
      for (const [key, value] of Object.entries(groupedData)) {
        await productionOrderScheduling({ id: key, production_task_items: value });
      }
      this.$message.success("排产完成");
      this.confirmLoading = false;
      this.cancel();
      this.$emit("create");
    },
    cancel() {
      this.$emit("cancel", false);
    },
  },
  watch: {
    async visible(value) {
      if (value) {
        workstationOption().then((data) => (this.workstationItems = data.results));
        userOption().then((data) => (this.userItems = data.results));

        this.queryLoading = true;
        const productionTaskItems = [];
        for (const item of this.items) {
          const data = await productionOrderProductionTask({ id: item.id });
          for (const taskItem of data) {
            productionTaskItems.push({
              ...taskItem,
              production_order: item.id,
              production_order_number: item.number,
              goods_number: item.goods_number,
              goods_name: item.goods_name,
              goods_spec: item.goods_spec,
            });
          }
        }
        this.productionTaskItems = productionTaskItems;
        this.queryLoading = false;
      }
    },
  },
};
</script>

<style scoped></style>
